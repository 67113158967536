




import Vue from 'vue'
import { auth } from '../main'
import { auth as firebaseuiauth } from 'firebaseui'
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import 'firebaseui/dist/firebaseui.css'

export default Vue.extend({
  name: 'firebase-login',

  data: () => ({
  }),
  mounted () {
    if (auth.currentUser) {
      this.$router.push('/')
    }

    var ui = firebaseuiauth.AuthUI.getInstance() || new firebaseuiauth.AuthUI(auth)
    var uiconfig = {
      callbacks: {
        signInSuccessWithAuthResult: () => {
          this.$router.push('/')
          return false
        },
        uiShown: function () {
          // placeholder
        }
      },
      signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID
      ]
    }
    ui.start('#firebaseui-auth-container', uiconfig)
  }
})
