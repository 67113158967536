




import Vue from 'vue'
import FirebaseLogin from '../components/FirebaseLogin.vue'

export default Vue.extend({
  name: 'login',

  components: {
    FirebaseLogin
  }
})
